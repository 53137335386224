/* You can add global styles to this file, and also import other style files */

@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-balham.css";

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2") format("woff2");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"),
    url("./assets/fonts/KFOmCnqEu92Fr1Mu7mxKOzY.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"),
    url("./assets/fonts/KFOmCnqEu92Fr1Mu7mxKOzY.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

html {
  height: 100vh;
}

body {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
}

.height100 {
  height: 100% !important;
}

.width100 {
  width: 100% !important;
}

.width95 {
  width: 95% !important;
}

.width70 {
  width: 70% !important;
}

.width65 {
  width: 65% !important;
}

.width60 {
  width: 60% !important;
}

.width55 {
  width: 55% !important;
}

.width45 {
  width: 45% !important;
}

.width40 {
  width: 40% !important;
}

.width30 {
  width: 30% !important;
}

.width20 {
  width: 20% !important;
}


.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.centered-absolute {
  position: absolute;
  display: inline-block;
  left: 50%;
  transform: translate(-50%, 0);
}

.centered-relative {
  position: relative;
  display: inline-block;
  left: 50%;
  transform: translate(-50%, 0);
}

.centered-v-relative {
  position: relative;
  display: inline-block;
  left: 50%;
  transform: translate(-50%, 50%);
}

.material-btn {
  cursor: pointer;
}

.cdk-overlay-pane {
  position: absolute !important;
  top: 10%;
}

@media only screen and (max-width: 1140px) {
  body {
    font-size: 1.25rem;
  }
}

.background-green {
  background-color: green !important;
}

.background-yellow {
  background-color: yellow !important;
}

.background-red {
  background-color: red !important;
}

.background-orange {
  background-color: orange !important;
}

.fit-size {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  width: auto !important;
}


/**** Login Styles *****/

.flag span:hover{
  color:white;
}

.logintranslate{
  background-color:black;
  justify-content: right;
}

.flag {
  color: white;
  text-decoration: none;
  margin-left: 1rem;
  margin-right: 0.4rem;
}


/*
.loginlogo{
  height: 6.2rem;
  width: 23.5rem;
   background-image: url("./assets/img/logotecdisma.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
} */
.login-logo {
  height: 6.2rem;
  width: 100%;
  margin-left: 10;
  background-image: url("./assets/img/KIOM.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-color: white;
  margin-top: 15px;
  border-bottom: 0 !important;
}

.login-logo>div {
  display: none;
}

.login-button-container {
  position: relative;
  bottom: 4rem;
  float: right;
  right: 3rem;
  font-size: 1.5rem;
  background-color: #bfbfbf;
  width: 6rem;
}

.login-button-container>button {
  color: #fff;
}

.login-button-container>button:active {
  outline: 0 !important;
  border: none;
}

.login-button-container>button:focus {
  outline: 0 !important;
}

.login-container-body {
  width: 100%;
  height: 86%;
  background-image: url("./assets/img/np_its.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.login-box-body-header {
  display: none !important;
}

.login-box-body {
  padding: 0 !important;
}
.enlacelogin{
 margin-right: 3rem;
 margin-top: 1.5rem;
 font-size: 1.5rem;
 cursor: pointer;
 background-color: #3B4151;
 color:white;
 width: 6rem;
 height: 2.5rem;
 border-style:none;
 border-radius: 28px 28px 28px 28px;
}

.enlacelogin:active{
  border-style: none;
}


/*loginbody{
  width: 100%;
  height: 30rem;
  /* background-image: url("./assets/img/bg.jpg") !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}*/

.enlacelogin:active {
  outline: none;
  border: none;
  }

.enlacelogin:focus {outline:0;}

.master-container {
  background-image: linear-gradient(rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)), url("./assets/img/np_its.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}


.header-navbar {
  background-color: rgba(60,141,188)!important;
}

/* Definicion de variables de color desde raiz */
:root {
  --sidenav-bg: rgba(34,45,50);
  --sidenav-bg-sublink: rgb(41, 55, 61);
  --sidenav-link-color: rgba(255,255,255);
  --sidenav-link-bg-active:rgb(57, 66, 71);
  --sidenav-link-color-active: rgba(255,255,255);
  --sidenav-link-border: rgba(26,187,156)

}

.sublink {
  background: var(--sidenav-bg-sublink);
}

.cursor-pointer {
  cursor: pointer;
}

.pointer-row:hover {
  background: rgba(60,141,188);
  cursor: pointer;
}
